import tr from 'vue2-datepicker/locale/es/tr'
import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/finance/revenue',
    name: 'revenue',
    component: () => import('@/views/finance/revenue/Revenue.vue'),
    meta: {
      pageTitle: 'Danh sách Khoản thu',
      breadcrumb: [
        {
          text: 'Quản lý tài chính',
          active: false,
        },
        {
          text: 'Khoản thu',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.REVENUE,
    },
  },
  {
    path: '/finance/revenue-plan',
    name: 'revenue_plan',
    component: () => import('@/views/finance/revenue-plan/RevenuePlan.vue'),
    meta: {
      pageTitle: 'Danh sách Kế hoạch thu',
      breadcrumb: [
        {
          text: 'Quản lý tài chính',
          active: false,
        },
        {
          text: 'Kế hoạch thu',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.REVENUE_PLAN,
    },
  },
  {
    path: '/finance/revenue-schedule',
    name: 'revenue_schedule',
    component: () => import('@/views/finance/revenue-schedule/RevenueSchedule.vue'),
    meta: {
      pageTitle: 'Dự kiến thu',
      breadcrumb: [
        {
          text: 'Quản lý tài chính',
          active: false,
        },
        {
          text: 'Dự kiến thu',
          active: false,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.REVENUE_SCHEDULE,
    },
  },
  {
    path: '/finance/exemption',
    name: 'exemption',
    component: () => import('@/views/finance/exemption/Exemption.vue'),
    meta: {
      pageTitle: 'Khoản miễn giảm',
      breadcrumb: [
        {
          text: 'Quản lý tài chính',
          active: false,
        },
        {
          text: 'Khoản miễn giảm theo đối tượng',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.EXEMPTION,
    },
  },
  {
    path: '/finance/receipt',
    name: 'receipt',
    component: () => import('@/views/finance/receipt/Receipt.vue'),
    meta: {
      pageTitle: 'Lập phiếu thu',
      breadcrumb: [
        {
          text: 'Quản lý tài chính',
          active: false,
        },
        {
          text: 'Lập phiếu thu',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.RECEIPT,
    },
  },
  {
    path: '/finance/receiptktk',
    name: 'receiptktk',
    component: () => import('@/views/finance/receipt/ReceiptKTK.vue'),
    meta: {
      pageTitle: 'Lập phiếu thu khoản thu khác',
      breadcrumb: [
        {
          text: 'Quản lý tài chính',
          active: false,
        },
        {
          text: 'Lập phiếu thu khác',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.RECEIPT,
    },
  },
]
