import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch } from '@/utils'
import { GRADUATION_TYPE } from '@/const/status'

export default {
  namespaced: true,
  state: {
    dataLists: [],
    classes: [],
    graduationType: GRADUATION_TYPE,
  },
  getters: {
    dataLists: state => state.dataLists,
    classes: state => state.classes,
    graduationType: state => state.graduationType,
  },
  mutations: {
    SET_DATA: (state, { records }) => {
      state.dataLists = records
    },
    SET_CLASS: (state, { data }) => {
      state.classes = data
    },
  },
  actions: {
    async getClasses({ commit }, params) {
      try {
        const response = await axios.get('uni/classes', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const mapData = data.data.records.map(item => ({ value: item.id, label: item.className }))
          commit('SET_CLASS', {
            data: mapData,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getData({ commit }, params) {
      try {
        const response = await axios.get('uni/graduations/graduation-condition', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_DATA', {
            records: response.data.data,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getDataAcademicPerformanceGrading({ commit }, params) {
      try {
        const response = await axios.get('uni/reports/academic-performance-grading', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_DATA', {
            records: response.data.data,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async updateGraduationType({ commit }, params) {
      try {
        const response = await axios.put('uni/graduations/update-graduation-type', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async setGraduationClass({ commit }, params) {
      try {
        const response = await axios.put('uni/graduations/set-graduation-class', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
