import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    techTransferMembers: [],
    employees: [],
    roles: [],
  },
  getters: {
    techTransferMembers: state => state.techTransferMembers,
    employees: state => state.employees,
  },
  mutations: {
    SET_TECH_TRANSFER_MEMBERS: (state, data) => { state.techTransferMembers = data },
    SET_EMPLOYEES: (state, data) => { state.employees = data },
  },
  actions: {
    async createTechTransferMember({ commit }, params) {
      try {
        const response = await axios.post('uni/researchTechTransferMembers', params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getTechTransferMembers({ commit }, params) {
      try {
        const response = await axios.get('uni/researchTechTransferMembers', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          commit('SET_TECH_TRANSFER_MEMBERS', records)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async updateTechTransferMember({ commit }, params) {
      try {
        const response = await axios.put(`uni/researchTechTransferMembers/${params.id}`, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteTechTransferMember({ commit }, id) {
      try {
        const response = await axios.delete(`uni/researchTechTransferMembers/${id}`)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getEmployeesByOrganizationId({ commit }, params) {
      try {
        const response = await axios.get('uni/employees/getAllByOrganizationId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_EMPLOYEES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
