import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    listData: [],
  },
  getters: {
    listData: state => state.listData,
  },
  mutations: {
    SET_DATA: (state, listData) => {
      state.listData = listData
    },
  },
  actions: {
    async getGenders({ commit }, params) {
      try {
        const response = await axios.get('uni/factGenders', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_DATA', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createGender({ commit }, params) {
      try {
        const response = await axios.post('uni/factGenders', params)
        const { data } = response
        const isSuccessful = data.code === ApiCode.SUCCESS
        if (isSuccessful) {
          return { isSuccessful, message: data.message }
        }
        return { isSuccessful, message: data.debugMessage }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateGender({ commit }, params) {
      try {
        const response = await axios.put(`uni/factGenders/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteGender({ commit }, id) {
      try {
        const response = await axios.delete(`uni/factGenders/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
