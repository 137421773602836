import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    studentAwards: [],
    totalRows: 0,
    allStudent: [],
    allAward: [],
  },
  getters: {
    studentAwards: state => state.studentAwards,
    totalRows: state => state.totalRows,
    allStudent: state => state.allStudent,
    allAward: state => state.allAward,
  },
  mutations: {
    SET_STUDENTAWARDS: (state, { total, records }) => {
      state.totalRows = total
      state.studentAwards = records
    },

    SET_ALL: (state, { allStudent, allAward }) => {
      state.allStudent = allStudent
      state.allAward = allAward
    },
  },
  actions: {
    async getStudentAwards({ commit }, params) {
      try {
        const response = await axios.get('uni/studentAwards', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_STUDENTAWARDS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createStudentAward({ commit }, params) {
      try {
        const response = await axios.post('uni/studentAwards', params)
        const { data } = response
        const isSuccessful = data.code === ApiCode.SUCCESS
        if (isSuccessful) {
          return { isSuccessful, message: data.message }
        }
        return { isSuccessful, message: data.debugMessage }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateStudentAward({ commit }, params) {
      try {
        const response = await axios.put(`uni/studentAwards/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteStudentAward({ commit }, id) {
      try {
        const response = await axios.delete(`uni/studentAwards/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
