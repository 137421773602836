import Vue from 'vue'
import VueRouter from 'vue-router'
import { getLoginType, isUserLoggedIn } from '@/auth/utils'
import { canNavigate } from '@/libs/acl/routeProtection'
import auth from '@/router/routes/auth'
import category from '@/router/routes/category'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import ability from '@/libs/acl/ability'
import programme from '@/router/routes/programme'
import studentallowance from '@/router/routes/studentAllowance'
import studentpolicies from '@/router/routes/studentPolicies'
import studentaward from '@/router/routes/studentAward'
import studentdiscipline from '@/router/routes/studentDiscipline'
import studentscholarship from '@/router/routes/studentScholarship'
import examinationroom from '@/router/routes/examinationRoom'
import studentexaminationroom from '@/router/routes/studentExaminationRoom'
import studentmanagement from '@/router/routes/studentmanagement'
import creditClass from '@/router/routes/credit-class'
import teacherManagement from '@/router/routes/teacherManagement'
import schedule from '@/router/routes/schedule'
import finance from '@/router/routes/finance'
import report from '@/router/routes/report'
import scoreLock from '@/router/routes/score-lock'
import configRegisterSubject from '@/router/routes/configRegisterSubject'
import graduation from '@/router/routes/graduation'
import articles from '@/router/routes/article'
import curriculumVitae from '@/router/routes/curriculum-vitae'
import diploma from '@/router/routes/diploma'
import studentCgpa from '@/router/routes/studentCgpa'
import studentTransfer from '@/router/routes/studentTransfer'
import studentSuspend from '@/router/routes/studentSuspend'
import carousel from '@/router/routes/carousel'
import logs from '@/router/routes/logActivities'
import maintenance from '@/router/routes/maintenance'
import research from '@/router/routes/research'
import { LOGIN_TYPE } from '@/const/type'
import qrReport from '@/router/routes/qr-report'
import majorApproval from '@/router/routes/major-approval'
import conduct from '@/router/routes/conduct'
import configAutoCreateStudentCode from '@/router/routes/configAutoCreateStudentCode'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
        action: 'READ',
        resource: 'DASHBOARD',
      },
    },
    ...auth,
    ...category,
    ...programme,
    ...studentallowance,
    ...studentmanagement,
    ...creditClass,
    ...studentaward,
    ...studentdiscipline,
    ...studentscholarship,
    ...examinationroom,
    ...studentexaminationroom,
    ...teacherManagement,
    ...schedule,
    ...finance,
    ...report,
    ...scoreLock,
    ...configRegisterSubject,
    ...studentpolicies,
    ...graduation,
    ...articles,
    ...curriculumVitae,
    ...diploma,
    ...studentCgpa,
    ...studentTransfer,
    ...studentSuspend,
    ...carousel,
    ...logs,
    ...maintenance,
    ...research,
    ...qrReport,
    ...majorApproval,
    ...conduct,
    ...configAutoCreateStudentCode,
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach(async (to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  if (!canNavigate(to)) {
    if (!isLoggedIn) return next({ name: 'login' })
    return next({ name: 'notAuthorized' })
  }
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next({ name: 'dashboard' })
  }
  if (isLoggedIn) {
    let user = null
    const loginType = getLoginType()
    if (loginType === LOGIN_TYPE.NORMAL) {
      user = await store.dispatch('auth/verify')
    } else if (loginType === LOGIN_TYPE.SSO) {
      user = await store.dispatch('auth/verifySso')
    }
    if (user) {
      ability.update(user.abilities)
    }
  }
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
