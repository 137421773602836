import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/credit-class/',
    name: 'creditClass',
    component: () => import('@/views/credit-class/CreditClass.vue'),
    meta: {
      pageTitle: 'Lớp độc lập theo môn học',
      breadcrumb: [
        {
          text: 'Quản lý đào tạo',
          active: false,
        },
        {
          text: 'Quản lý lớp độc lập',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.CREDIT_CLASS,
    },
  },
  {
    path: '/credit-class/teacher/:id',
    name: 'creditClassTeacher',
    component: () => import('@/views/credit-class-teacher/CreditClassTeacher.vue'),
    meta: {
      pageTitle: 'Thêm giảng viên vào Lớp độc lập',
      breadcrumb: [
        {
          text: 'Quản lý lớp độc lập',
          active: false,
        },
        {
          text: 'Thêm giảng viên',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: 'CREDIT_CLASS_TEACHER',
    },
  },
  {
    path: '/credit-class/student/:id',
    name: 'creditClassStudent',
    component: () => import('@/views/credit-class-student/CreditClassStudent.vue'),
    meta: {
      pageTitle: 'Thêm sinh viên vào Lớp độc lập',
      breadcrumb: [
        {
          text: 'Quản lý lớp độc lập',
          active: false,
        },
        {
          text: 'Thêm sinh viên',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: 'CREDIT_CLASS_STUDENT',
    },
  },
]
