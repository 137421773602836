import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/programme',
    name: 'programme',
    component: () => import('@/views/programme/Programme.vue'),
    meta: {
      pageTitle: 'Danh sách Chương trình đào tạo',
      breadcrumb: [
        {
          text: 'Quản lý đào tạo',
          active: false,
        },
        {
          text: 'Quản lý Chương trình đào tạo',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.PROGRAMME,
    },
  },
  {
    path: '/programme/subject/:id',
    name: 'programmeSubject',
    component: () => import('@/views/programme-subject/ProgrammeSubject.vue'),
    meta: {
      pageTitle: 'Chi tiết môn học trong Chương trình đào tạo',
      breadcrumb: [
        {
          text: 'Quản lý đào tạo',
          active: false,
        },
        {
          text: 'Chương trình đào tạo',
          active: false,
        },
        {
          text: 'Chi tiết',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.PROGRAMME,
    },
  },
]
