import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import {
  COUNT_4_GPA_SYMBOLS,
  IS_OPTIONAL_SYMBOLS,
  PROGRAMME_STATUSES,
  PROGRAMME_SUBJECT_TYPE,
  STATUS_SYMBOLS,
  REPLACE_THESIS_SUBJECT_SYMBOLS, REPLACE_THESIS_SUBJECT_TYPES,
} from '@/const/status'

export default {
  namespaced: true,
  state: {
    dataLists: [],
    totalRows: 0,
    statuses: PROGRAMME_STATUSES,
    statusSymbols: STATUS_SYMBOLS,
    countedForGpaTypes: STATUS_SYMBOLS,
    isOptionalTypes: IS_OPTIONAL_SYMBOLS,
    programmeSubjectTypes: PROGRAMME_SUBJECT_TYPE,
    replaceThesisSubjectTypes: REPLACE_THESIS_SUBJECT_TYPES,
    resourceName: { fullName: 'Môn học CTĐT', shortName: 'HP' },
    maxOrderNo: 0,
    subjects: [],
  },
  getters: {
    dataLists: state => state.dataLists,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
    statusSymbols: state => state.statusSymbols,
    programmeSubjectTypes: state => state.programmeSubjectTypes,
    resourceName: state => state.resourceName,
    maxOrderNo: state => state.maxOrderNo,
    subjects: state => state.subjects,
    countedForGpaTypes: state => state.countedForGpaTypes,
    isOptionalTypes: state => state.isOptionalTypes,
    replaceThesisSubjectTypes: state => state.replaceThesisSubjectTypes,
  },
  mutations: {
    SET_LISTS: (state, { total, records }) => {
      state.totalRows = total
      state.dataLists = records
    },
    SET_STATUSES: (state, statuses) => {
      state.statuses = statuses
    },
    SET_MAX_ORDER_NO: (state, maxOrderNo) => {
      state.maxOrderNo = maxOrderNo
    },
    SET_SUBJECTS: (state, records) => {
      state.subjects = records
    },
  },
  actions: {
    async getData({ commit }, params) {
      try {
        const response = await axios.get('uni/programme/subjects', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_LISTS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async createData(commit, params) {
      try {
        const response = await axios.post('uni/programme/subjects', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async updateData({ commit }, params) {
      try {
        const response = await axios.put(`uni/programme/subjects/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async deleteData({ commit }, id) {
      try {
        const response = await axios.delete(`uni/programme/subjects/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getMaxOrderNo({ commit }, id) {
      try {
        const response = await axios.get(`uni/programme/subjects/max/order-no/${id}`)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { maxOrderNo } = data.data
          commit('SET_MAX_ORDER_NO', maxOrderNo)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getSubjects({ commit }, params) {
      try {
        const response = await axios.get('uni/subjects', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = records.map(item => ({ value: item.id, label: `[${item.code}] ${item.name}` }))
          commit('SET_SUBJECTS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async importProgrammeSubject({ commit }, params) {
      try {
        const response = await axios.post('uni/programme/subjects/importProgrammeSubject', { params })
        const { data } = response
        return data;
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
