import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'
import {baseAttr} from "@/store/student-transfer";

export default {
  namespaced: true,
  actions: {
    async saveStudentRemakeExamination({ commit }, body) {
      try {
        const response = await axios.post('uni/student-remake-examination', body)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async updateData({ commit }, params) {
      try {
        const response = await axios.put(`uni/student-remake-examination/${params.id}`, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
