import axios from '@/libs/axios'
import {ApiCode} from '@/const/api'
import {handleCatch} from '@/utils'
import {PAYMENT_STATUSES} from "@/const/status";

export default {
  namespaced: true,
  state: {
    dataList: [],
    totalRows: 0,
    statuses: PAYMENT_STATUSES,
  },
  getters: {
    dataList: state => state.dataList,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
  },
  mutations: {
    SET_DATA: (state, {total, records}) => {
      state.totalRows = total
      state.dataList = records
    },
  },
  actions: {
    async readData({commit}, params) {
      try {
        const response = await axios.get('uni/paymentStatusOfStudents', {params})
        const {data} = response
        if (data.code === ApiCode.SUCCESS) {
          const {total, records} = data.data
          commit('SET_DATA', {total, records})
        }
      } catch (e) {
        handleCatch(e)
      }
    }
  },
}
