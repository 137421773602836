import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    employees: [],
    total: 0,
    statuses: [],
    departments: [],
    titles: [],
    academicRanks: [],
    academicDegrees: [],
    defaultPassword: '',
    employeeDetail: null,
  },
  getters: {
    employees: state => state.employees,
    total: state => state.total,
    statuses: state => state.statuses,
    departments: state => state.departments,
    titles: state => state.titles,
    defaultPassword: state => state.defaultPassword,
    employeeDetail: state => state.employeeDetail,
    academicDegrees: state => state.academicDegrees,
    academicRanks: state => state.academicRanks,
  },
  mutations: {
    SET_EMPLOYEES: (state, employees) => { state.employees = employees },
    SET_TOTAL: (state, total) => { state.total = total },
    SET_STATUS: (state, statuses) => { state.statuses = statuses },
    SET_DEPARTMENTS: (state, departments) => { state.departments = departments },
    SET_TITLES: (state, titles) => { state.titles = titles },
    SET_DEFAULT_PASSWORD: (state, data) => { state.defaultPassword = data },
    SET_EMPLOYEE_DETAIL: (state, data) => { state.employeeDetail = data },
    SET_RANKS: (state, data) => { state.academicRanks = data },
    SET_DEGREES: (state, data) => { state.academicDegrees = data },
  },
  actions: {

    async getEmployee({ commit }, params) {
      try {
        const response = await axios.get('uni/employees', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const { total } = data.data
          commit('SET_EMPLOYEES', records)
          commit('SET_TOTAL', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async updateEmployee({ commit }, dataObj) {
      try {
        const response = await axios.put('uni/employees', dataObj)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async createEmployee({ commit }, dataObj) {
      try {
        const response = await axios.post('uni/employees', dataObj)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async deleteEmployee({ commit }, id) {
      try {
        const response = await axios.delete(`uni/employees/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async getTitles({ commit }, params) {
      try {
        const response = await axios.get('uni/factTitles/selection', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { titles } = data.data
          commit('SET_TITLES', titles)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getRanks({ commit }, params) {
      try {
        const response = await axios.get('uni/factAcademicRanks/allActive', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_RANKS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getDegrees({ commit }, params) {
      try {
        const response = await axios.get('uni/factAcademicDegrees/allActive', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_DEGREES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getDepartments({ commit }, params) {
      try {
        const response = await axios.get('uni/departments', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          commit('SET_DEPARTMENTS', records)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getEmployeesNotLinkingAccount({ commit }, params) {
      try {
        const response = await axios.get('uni/employees/notLinkingAccount', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_EMPLOYEES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async uploadAvatar({ commit }, { id, params }) {
      try {
        const response = await axios.put(`uni/employees/${id}/uploadAvatar`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, data: data.data }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async getLinkAvatar({ commit }, link) {
      try {
        const response = await axios.get(`uni/employees/getFileMinioByPathFile?pathFile=${link}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, data: data.data }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async getDefaultPassword({ commit }, code) {
      try {
        const response = await axios.get(`uni/parameters/code/${code}`)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { value } = data.data
          commit('SET_DEFAULT_PASSWORD', value)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getEmployeeDetail({ commit }, params) {
      try {
        const response = await axios.get(`uni/employees/${params.id}`)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_EMPLOYEE_DETAIL', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async importEmployee({ commit }, params) {
      try {
        const response = await axios.post('uni/employees/import', { params })
        const { data } = response
        return {
          code: data.code,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
