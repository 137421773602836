import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch } from '@/utils'
import { CREDIT_CLASS_STATUSES } from '@/const/status'

export const baseAttr = Object.freeze({
  basePath: ApiExtRoutes.creditClass,
  maxOrderNoPath: ApiExtRoutes.creditClassMaxOrderNo,
  copyPath: ApiExtRoutes.creditClassCopy,
  planPath: ApiExtRoutes.planCreditClass,
  bulkApprove: ApiExtRoutes.bulkApproveCreditClass,
})
export default {
  namespaced: true,
  state: {
    dataLists: [],
    totalRows: 0,
    statuses: CREDIT_CLASS_STATUSES,
    resourceName: {
      fullName: 'Lớp độc lập',
      shortName: 'Lớp ĐL',
    },
    maxOrderNo: 0,
    practiceScoreStudentOptions: [],
    planDataLists: [],
    planTotalRows: 0,
    processScores: [],
    learningResult: [],
    nextCode: '',
    dataExport: null,
  },
  getters: {
    dataLists: state => state.dataLists,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
    resourceName: state => state.resourceName,
    maxOrderNo: state => state.maxOrderNo,
    planDataLists: state => state.planDataLists,
    planTotalRows: state => state.planTotalRows,
    processScores: state => state.processScores,
    learningResult: state => state.learningResult,
    nextCode: state => state.nextCode,
    dataExport: state => state.dataExport,
  },
  mutations: {
    SET_LISTS: (state, { total, records }) => {
      state.totalRows = total
      state.dataLists = records
    },
    SET_MAX_ORDER_NO: (state, maxOrderNo) => {
      state.maxOrderNo = maxOrderNo
    },
    SET_NEXT_CODE: (state, nextCode) => {
      state.nextCode = nextCode
    },
    SET_CREDIT_CLASSES: (state, data) => {
      state.dataLists = data
    },
    SET_PLAN_LISTS: (state, { total, records }) => {
      state.planTotalRows = total
      state.planDataLists = records
    },
    SET_PROCESS_SCORES: (state, data) => {
      state.processScores = data
    },
    SET_LEARNING_RESULT: (state, data) => {
      state.learningResult = data
    },
    SET_DATA_EXPORT: (state, { dataExport }) => {
      state.dataExport = dataExport
    },
  },
  actions: {
    async getData({ commit }, params) {
      try {
        const response = await axios.get(baseAttr.basePath, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const {
            total,
            records,
          } = data.data
          commit('SET_LISTS', {
            total,
            records,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createData({ commit }, params) {
      try {
        const response = await axios.post(baseAttr.basePath, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async copyData({ commit }, params) {
      try {
        const response = await axios.post(`${baseAttr.copyPath}${params.classId}`, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async updateData({ commit }, params) {
      try {
        const response = await axios.put(`${baseAttr.basePath}/${params.id}`, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async deleteData({ commit }, id) {
      try {
        const response = await axios.delete(`${baseAttr.basePath}/${id}`)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getMaxOrderNo({ commit }, params) {
      try {
        const response = await axios.get(`${baseAttr.maxOrderNoPath}`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { maxOrderNo } = data.data
          commit('SET_MAX_ORDER_NO', maxOrderNo)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getNextCode({ commit }, params) {
      try {
        const response = await axios.get(`${baseAttr.basePath}/next-code`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { code } = data.data
          commit('SET_NEXT_CODE', code)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async importStudentCreditClass({ commit }, params) {
      try {
        const response = await axios.post('uni/studentsCreditClasses/importStudent', { params })
        const { data } = response
        return data
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getScoreLockCreditClasses({ commit }, params) {
      try {
        const response = await axios.get('uni/credit_classes/scoreLock', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_LISTS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async updateScoreLockCreditClasses({ commit }, params) {
      try {
        const response = await axios.put('uni/credit_classes/scoreLock', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async getPlanData({ commit }, params) {
      try {
        const response = await axios.get(baseAttr.planPath, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const {
            total,
            records,
          } = data.data
          commit('SET_PLAN_LISTS', {
            total,
            records,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async createPlanData({ commit }, params) {
      try {
        const response = await axios.post(baseAttr.planPath, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getLearningResult({ commit }, params) {
      try {
        const response = await axios.get('uni/studentsCreditClasses/learningResult', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_LEARNING_RESULT', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getProcessScores({ commit }, params) {
      try {
        const response = await axios.get('uni/studentsCreditClasses/processScores', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_PROCESS_SCORES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async saveProcessScores({ commit }, params) {
      try {
        const response = await axios.post('uni/studentsCreditClasses/processScores', params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
          countEmptyScore: data.data,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async bulkApproveData({ commit }, params) {
      try {
        const response = await axios.put(baseAttr.bulkApprove, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async exportPdfProcessScores({ commit }, params) {
      try {
        const response = await axios.get('uni/reports/exportPdfProcessScores', { params, responseType: 'blob' })
        if (response.status === 200) {
          return response.data
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async downloadExport({ commit }, params) {
      try {
        const responseLearningResult = await axios({
          method: 'get',
          url: 'uni/studentsCreditClasses/exportLearningResult',
          responseType: 'blob',
          params,
        })
        commit('SET_DATA_EXPORT', { dataExport: responseLearningResult })
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
