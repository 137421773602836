import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/conduct-criteria-group',
    name: 'conductCriteriaGroup',
    component: () => import('@/views/admin/category/conduct-criteria-group/Index.vue'),
    meta: {
      pageTitle: 'Danh sách nhóm tiêu chí đánh giá',
      breadcrumb: [
        {
          text: 'Công tác Học sinh - Sinh viên',
          active: false,
        },
        {
          text: 'Danh sách nhóm tiêu chí đánh giá',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.CONDUCT_CRITERIA_GROUP,
    },
  },
]
