import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/teacherManagement/teachers',
    name: 'teachers',
    component: () => import('@/views/admin/teacherManagement/teachers/Teachers.vue'),
    meta: {
      pageTitle: 'Quản lý giảng viên',
      breadcrumb: [
        {
          text: 'Quản lý nhân sự',
          active: false,
        },
        {
          text: 'Quản lý giảng viên',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.TEACHERS,
    },
  },
  {
    path: '/teacherDocument',
    name: 'teacherDocument',
    component: () => import('@/views/teacher-document/TeacherDocument.vue'),
    meta: {
      pageTitle: 'Danh sách giáo trình',
      breadcrumb: [
        {
          text: 'Quản lý đào tạo',
          active: false,
        },
        {
          text: 'Giáo trình',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.TEACHER_DOCUMENT,
    },
  },
]
