import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import { SchedulePlugin } from '@syncfusion/ej2-vue-schedule'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import CKEditor from 'ckeditor4-vue'
import router from './router'
import store from './store'
import App from './App.vue'
import VueSocketIO from 'vue-socket.io'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import '@/libs/firebase'

// Sử dụng VueSocketIO với kết nối đã thiết lập
Vue.use(new VueSocketIO({
  connection: process.env.VUE_APP_WEBSOCKET_URL,
}))

Vue.use(SchedulePlugin)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(CKEditor)

// Composition API
Vue.use(VueCompositionAPI)

// Vue recaptcha
Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_GG_RECAPTCHA_KEY,
  loaderOptions: {
    autoHideBadge: true,
  },
})

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
