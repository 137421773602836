export const ROOM_TYPES = [
  { value: 1, label: 'Phòng lý thuyết' },
  { value: 2, label: 'Phòng máy tính' },
  { value: 3, label: 'Phòng thí nghiệm' },
]

export const SUBJECT_TYPES = [
  { value: 'TC', label: 'Thể chất' },
  { value: 'QP', label: 'Quốc phòng' },
]

export const SCORE_TYPES = [
  { value: 1, text: 'Lý thuyết' },
  { value: 2, text: 'Thực hành' },
]

export const ResearchRoleType = Object.freeze({
  MEMBER: 1,
  REVIEWER: 2,
})

export const OSType = Object.freeze({
  WEB: 3,
})

export const LOGIN_TYPE = Object.freeze({
  NORMAL: '1',
  SSO: '2',
})
