import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    trainingSystems: [],
    courses: [],
    majors: [],
    students: [],
    totalRows: 0,
  },
  getters: {
    trainingSystems: state => state.trainingSystems,
    courses: state => state.courses,
    majors: state => state.majors,
    students: state => state.students,
    totalRows: state => state.totalRows,
  },
  mutations: {
    SET_TRAINING_SYSTEMS: (state, data) => { state.trainingSystems = data },
    SET_COURSES: (state, data) => { state.courses = data },
    SET_MAJORS: (state, data) => { state.majors = data },
    SET_TOTAL_ROWS: (state, data) => { state.totalRows = data },
    SET_STUDENTS: (state, data) => { state.students = data },
  },
  actions: {
    async getTrainingSystemsByOrganizationId({ commit }, params) {
      try {
        const response = await axios.get('uni/trainingSystems/getByOrganizationId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_TRAINING_SYSTEMS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getCoursesByTrainingSystemId({ commit }, params) {
      try {
        const response = await axios.get('uni/courses/getByTrainingSystemId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_COURSES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getMajorsByOrganizationId({ commit }, params) {
      try {
        const response = await axios.get('uni/majors/getByOrganizationId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_MAJORS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getCertificatedStudents({ commit }, params) {
      try {
        const response = await axios.get('uni/reports/certificatedStudents', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records, total } = data.data
          commit('SET_STUDENTS', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
