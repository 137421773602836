import Vue from 'vue'
import Vuex from 'vuex'

// Modules
// eslint-disable-next-line import/no-cycle
import auth from '@/store/auth'
import menu from '@/store/authorization/menu'
import permission from '@/store/authorization/permission'
import award from '@/store/category/award'
import revenuetype from '@/store/category/revenuetype'
import allowance from '@/store/category/allowance'
import discipline from '@/store/category/discipline'
import policies from '@/store/category/policies'
import role from '@/store/authorization/role'
import programme from '@/store/programme'
import semesterType from '@/store/category/semesterType'
import semester from '@/store/category/semester'
import category from '@/store/category'
import building from '@/store/category/building'
import classes from '@/store/category/classes'
import rooms from '@/store/category/rooms'
import trainingForm from '@/store/category/fact-training-form'
import trainingSystem from '@/store/category/trainingSystem'
import course from '@/store/category/course'
import account from '@/store/authorization/account'
import resource from '@/store/authorization/resource'
import studentAllowance from '@/store/studentallowance'
import studentPolicies from '@/store/student-policies'
import studentAward from '@/store/studentaward'
import studentCgpa from '@/store/student-cgpa'
import studentDiscipline from '@/store/studentdiscipline'
import studentScholarship from '@/store/studentscholarship'
import programmeSubject from '@/store/programme-subject'
import dropdown from '@/store/common/dropdown'
import creditClass from '@/store/credit-class'
import department from '@/store/category/department'
import major from '@/store/category/major'
import subjectType from '@/store/category/subjectType'
import subject from '@/store/category/subject'
import examinationRoom from '@/store/examinationroom'
import studentExaminationRoom from '@/store/studentexaminationroom'
import employee from '@/store/category/employee'
import teacherTeam from '@/store/category/teacherTeam'
import creditClassTeacher from '@/store/credit-class-teacher'
import creditClassStudent from '@/store/credit-class-student'
import importStudent from '@/store/import-student'
import teacher from '@/store/teacherManagement/teachers'
import teacherSubject from '@/store/teacherManagement/teacher-subject'
import schedule from '@/store/schedule'
import day from '@/store/day'
import turn from '@/store/turn'
import revenue from '@/store/finance/revenue'
import revenuePlan from '@/store/finance/revenue_plan'
import revenueSchedule from '@/store/finance/revenue_schedule'
import exemption from '@/store/finance/exemption'
import receipt from '@/store/finance/receipt'
import courseSemester from '@/store/course-semester'
import studentClass from '@/store/student-class'
import reportTeachingExamCondition from '@/store/report/teaching-exam-condition'
import reportPointByCreditClass from '@/store/report/point-by-class-report'
import creditClassRoom from '@/store/credit-class-room'
import avgStudentClass from '@/store/report/avg-student-class'
import student from '@/store/category/student'
import classReceiptsCourseSemester from '@/store/report/class-receipts-course-semester'
import creditClassStudentPoint from '@/store/report/credit-class-student-point'
import reportPaymentStatusByCourseSemester from '@/store/report/payment-status-by-course-semester'
import conduct from '@/store/category/conduct'
import graduationCondition from '@/store/graduation/graduation_condition'
import conductCriteria from '@/store/category/conduct-criteria'
import graduationClass from '@/store/graduation/graduation-class'
import programmeSubjectGroup from '@/store/programme-subject-group'
import portalArticle from '@/store/category/portal_article'
import diploma from '@/store/diploma'
import diplomaStudent from '@/store/diploma-student'
import studentReserve from '@/store/student-reserve'
import curriculumVitae from '@/store/curriculum-vitae'
import reportTeacherSpecializationAssignment from '@/store/report/teacher-specialization-assignment'
import classLearningAlert from '@/store/report/class-learning-alert'
import diplomaPlanStudentClass from '@/store/diploma-plan-student-class'
import parameter from '@/store/parameter'
import examinationScheduler from '@/store/examination-scheduler'
import calculateCgpa from '@/store/calculate-cgpa'
import examinationSchedulerSubject from '@/store/examination-scheduler-subject'
import creditClassBySubject from '@/store/report/credit-class-by-subject'
import teacherDocument from '@/store/teacher-document'
import creditClassMedia from '@/store/credit-class-media'
import accountFcmToken from '@/store/account-fcm-token'
import notification from '@/store/notification'
import studentTransfer from '@/store/student-transfer'
import retakeExamSecondTime from '@/store/report/retake-exam-second-time'
import studentBeRepeated from '@/store/report/student-be-repeated'
import examinationRoomTeacher from '@/store/examination-room-teacher'
import semesterExam from '@/store/report/semester-exam'
import programmeReplaceThesisSubject from '@/store/programme-replace-thesis-subject'
import studentSuspend from '@/store/student-suspend'
import documents from '@/store/documents'
import reportCertification from '@/store/report/certification'
import logActivities from '@/store/log_activities'
import carousel from '@/store/fact-carousel'
import maintenance from '@/store/maintenance'
import maintenanceBackup from '@/store/maintenance-backup'
import maintenanceRecovery from '@/store/maintenance-recovery'
import studentsRegisteredBySubject from '@/store/report/students_registered_by_subject'
import research from '@/store/research'
import reviewBoard from '@/store/research/review-board'
import factScientificTopics from '@/store/research/fact_scientific_topics'
import factTopicLevels from '@/store/research/fact_topic_levels'
import factResearchRoles from '@/store/research/fact_research_roles'
import factResearchFields from '@/store/research/fact_research_fields'
import reviewer from '@/store/research/reviewer'
import member from '@/store/research/member'
import approvalResearch from '@/store/research/approval-research'
import researchArticle from '@/store/research/article'
import researchArticleRole from '@/store/research/research_article_role'
import manageReport from '@/store/report/manage'
import scoreLockPassword from '@/store/score-lock-password'
import deductLevel from '@/store/examination/deduct-level'
import scoringFormula from '@/store/scoring-formula'
import generalScore from '@/store/general-score'
import componentScore from '@/store/component-score'
import invention from '@/store/research/invention'
import factResearchInventionType from '@/store/category/fact-research-invention-type'
import inventionMember from '@/store/research/invention-member'
import techTransfer from '@/store/research/tech-transfer'
import techTransferMember from '@/store/research/tech-transfer-member'
import researchStudent from '@/store/research/student'
import researchStudentMember from '@/store/research/student-member/index'
import researchInstructor from '@/store/research/instructor/index'
import factResearchArticleRole from '@/store/category/fact-research-article-role'
import researchArticleMember from '@/store/research/article-member/index'
import factProvince from '@/store/category/fact-province'
import factNationality from '@/store/category/fact-nationality'
import factAward from '@/store/category/fact-award'
import factReligion from '@/store/category/fact-religion'
import factStudentStatus from '@/store/category/fact-student-status'
import factStudentCreditClassStatus from '@/store/category/fact-student-credit-class-status'
import factWard from '@/store/category/fact-ward'
import factArticleCategory from '@/store/category/fact-article-category'
import factInfringe from '@/store/category/fact-infringe'
import factScholarship from '@/store/category/fact-scholarship'
import factTitle from '@/store/category/fact-title'
import factYear from '@/store/category/fact-year'
import factOrganization from '@/store/category/fact-organization'
import systemParameter from '@/store/category/systemParameter'
import qrEmployeeStudent from '@/store/qr-report/qr-employee-student'
import factDiscipline from '@/store/category/fact-discipline'
import factEthnic from '@/store/category/fact-ethnic'
import chart from '@/store/category/chart'
import departmentType from '@/store/category/department-type'
import countStudentRegisterSubject from '@/store/report/count-student-register-subject'
import studentCancelSubject from '@/store/report/student-cancel-subject'
import factAcademicRank from '@/store/category/fact-academic-rank'
import factAcademicDegree from '@/store/category/fact-academic-degree'
import majorApproval from '@/store/major-approval'
import specialize from '@/store/major-approval/specialize'
import studentChoice from '@/store/major-approval/student-choice'
import majorApprovalStudent from '@/store/major-approval/major-approval-student'
import gender from './category/gender'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import conductCriteriaGroup from '@/store/category/conduct-criteria-group'
import studentRemakeExamination from '@/store/student_remake_examination'
import configStudentCode from '@/store/config-student-code'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    menu,
    category,
    building,
    classes,
    rooms,
    permission,
    award,
    allowance,
    discipline,
    policies,
    role,
    programme,
    semesterType,
    semester,
    trainingForm,
    trainingSystem,
    course,
    account,
    resource,
    studentAllowance,
    studentAward,
    studentCgpa,
    studentDiscipline,
    studentScholarship,
    programmeSubject,
    dropdown,
    creditClass,
    department,
    major,
    subjectType,
    subject,
    examinationRoom,
    studentExaminationRoom,
    employee,
    teacherTeam,
    importStudent,
    creditClassTeacher,
    creditClassStudent,
    teacher,
    teacherSubject,
    schedule,
    day,
    turn,
    revenue,
    revenuePlan,
    exemption,
    revenueSchedule,
    receipt,
    courseSemester,
    studentClass,
    revenuetype,
    reportTeachingExamCondition,
    reportPointByCreditClass,
    student,
    creditClassRoom,
    avgStudentClass,
    conduct,
    studentPolicies,
    classReceiptsCourseSemester,
    reportPaymentStatusByCourseSemester,
    creditClassStudentPoint,
    graduationCondition,
    conductCriteria,
    graduationClass,
    programmeSubjectGroup,
    studentReserve,
    portalArticle,
    curriculumVitae,
    reportTeacherSpecializationAssignment,
    diploma,
    diplomaStudent,
    classLearningAlert,
    diplomaPlanStudentClass,
    parameter,
    examinationScheduler,
    examinationSchedulerSubject,
    calculateCgpa,
    creditClassBySubject,
    teacherDocument,
    creditClassMedia,
    accountFcmToken,
    notification,
    studentTransfer,
    retakeExamSecondTime,
    studentBeRepeated,
    examinationRoomTeacher,
    semesterExam,
    programmeReplaceThesisSubject,
    studentSuspend,
    documents,
    reportCertification,
    studentsRegisteredBySubject,
    logActivities,
    carousel,
    maintenance,
    maintenanceBackup,
    maintenanceRecovery,
    reviewBoard,
    factScientificTopics,
    factTopicLevels,
    factResearchRoles,
    factResearchFields,
    research,
    reviewer,
    member,
    approvalResearch,
    researchArticle,
    researchArticleRole,
    manageReport,
    scoreLockPassword,
    deductLevel,
    scoringFormula,
    generalScore,
    componentScore,
    invention,
    factResearchInventionType,
    inventionMember,
    techTransfer,
    techTransferMember,
    researchStudent,
    researchStudentMember,
    researchInstructor,
    factResearchArticleRole,
    researchArticleMember,
    gender,
    factProvince,
    factNationality,
    factAward,
    factReligion,
    factStudentStatus,
    factStudentCreditClassStatus,
    factWard,
    factArticleCategory,
    factDiscipline,
    factInfringe,
    factTitle,
    factScholarship,
    factYear,
    factOrganization,
    systemParameter,
    factEthnic,
    chart,
    departmentType,
    qrEmployeeStudent,
    countStudentRegisterSubject,
    studentCancelSubject,
    factAcademicRank,
    factAcademicDegree,
    majorApproval,
    specialize,
    majorApprovalStudent,
    studentChoice,
    conductCriteriaGroup,
    studentRemakeExamination,
    configStudentCode
  },
  strict: process.env.DEV,
})
