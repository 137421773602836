import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/admin/category/building',
    name: 'categoryBuilding',
    component: () => import('@/views/admin/category/building/index.vue'),
    meta: {
      pageTitle: 'Quản lý tòa nhà',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Quản lý tòa nhà',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.BUILDING,
    },
  },
  {
    path: '/admin/category/class',
    name: 'class',
    component: () => import('@/views/admin/category/class/Class.vue'),
    meta: {
      pageTitle: 'Quản lý lớp cố định',
      breadcrumb: [
        {
          text: 'Quản lý sinh viên',
          active: false,
        },
        {
          text: 'Quản lý lớp cố định',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.CLASSES,
    },
  },
  {
    path: '/admin/category/rooms',
    name: 'rooms',
    component: () => import('@/views/admin/category/rooms/Rooms.vue'),
    meta: {
      pageTitle: 'Quản lý phòng học',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Phòng học',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.ROOMS,
    },
  },
  {
    path: '/category/factScholarship',
    name: 'factScholarship',
    component: () => import('@/views/admin/category/fact-scholarship/Index.vue'),
    meta: {
      pageTitle: 'Quản lý quỹ Học bổng',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Học bổng',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_SCHOLARSHIP,
    },
  },
  {
    path: '/category/factAward',
    name: 'factAward',
    component: () => import('@/views/admin/category/fact-award/Index.vue'),
    meta: {
      pageTitle: 'Hình thức khen thưởng',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Hình thức khen thưởng',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_AWARD,
    },
  },
  {
    path: '/category/factNationality',
    name: 'factNationality',
    component: () => import('@/views/admin/category/fact-nationality/Index.vue'),
    meta: {
      pageTitle: 'Danh mục quốc tịch',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Danh mục quốc tịch',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_NATIONALITY,
    },
  },
  {
    path: '/category/factReligion',
    name: 'factReligion',
    component: () => import('@/views/admin/category/fact-religion/Index.vue'),
    meta: {
      pageTitle: 'Danh mục tôn giáo',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Danh mục tôn giáo',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_RELIGION,
    },
  },
  {
    path: '/category/factProvince',
    name: 'factProvince',
    component: () => import('@/views/admin/category/fact-province/Index.vue'),
    meta: {
      pageTitle: 'Danh mục tỉnh thành',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Danh mục tỉnh thành',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_PROVINCE,
    },
  },
  {
    path: '/category/revenueType',
    name: 'revenueType',
    component: () => import('@/views/admin/category/revenuetype/RevenueType.vue'),
    meta: {
      pageTitle: 'Danh sách Loại khoản thu',
      breadcrumb: [
        {
          text: 'Quản lý tài chính',
          active: false,
        },
        {
          text: 'Loại khoản thu',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.REVENUETYPES,
    },
  },
  {
    path: '/category/discipline',
    name: 'discipline',
    component: () => import('@/views/admin/category/fact-discipline/Index.vue'),
    meta: {
      pageTitle: 'Hình thức kỷ luật',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Hình thức kỷ luật',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_DISCIPLINE,
    },
  },
  {
    path: '/category/factInfringe',
    name: 'factInfringe',
    component: () => import('@/views/admin/category/fact-infringe/Index.vue'),
    meta: {
      pageTitle: 'Hình thức vi phạm',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Hình thức vi phạm',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_INFRINGE,
    },
  },
  {
    path: '/category/systemParameter',
    name: 'systemParameter',
    component: () => import('@/views/admin/category/systemParameter/Index.vue'),
    meta: {
      pageTitle: 'Tham số hệ thống',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Tham số hệ thống',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.SYSTEM_PARAMETER,
    },
  },
  {
    path: '/category/factYear',
    name: 'factYear',
    component: () => import('@/views/admin/category/fact-year/Index.vue'),
    meta: {
      pageTitle: 'Năm học',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Năm học',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_YEAR,
    },
  },
  {
    path: '/category/policies',
    name: 'policies',
    component: () => import('@/views/admin/category/policies/Policies.vue'),
    meta: {
      pageTitle: 'Danh sách Loại chế độ chính sách',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Danh sách Loại chế độ chính sách',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_POLICY,
    },
  },
  {
    path: '/category/allowance',
    name: 'allowance',
    component: () => import('@/views/admin/category/allowance/Allowance.vue'),
    meta: {
      pageTitle: 'Danh sách Trợ cấp sư phạm',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Danh sách Trợ cấp sư phạm',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.ALLOWANCE,
    },
  },
  {
    path: '/category/departments',
    name: 'departments',
    component: () => import('@/views/admin/category/department/Departments.vue'),
    meta: {
      pageTitle: 'Dánh sách Khoa/Bộ môn/Phòng ban',
      breadcrumb: [
        {
          text: 'Quản lý nhân sự',
          active: true,
        },
        {
          text: 'Khoa/Bộ môn/Phòng ban',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.DEPARTMENT,
    },
  },
  {
    path: '/category/specialize',
    name: 'majors',
    component: () => import('@/views/admin/category/major/Major.vue'),
    meta: {
      pageTitle: 'Quản lý ngành học',
      breadcrumb: [
        {
          text: 'Quản lý đào tạo',
          active: false,
        },
        {
          text: 'Quản lý ngành học',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.MAJOR,
    },
  },
  {
    path: '/category/subjectTypes',
    name: 'subjectTypes',
    component: () => import('@/views/admin/category/subjectType/SubjectTypes.vue'),
    meta: {
      pageTitle: 'Danh sách Loại môn học',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Danh sách loại môn học',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.SUBJECT_TYPE,
    },
  },
  {
    path: '/category/subjects',
    name: 'subjects',
    component: () => import('@/views/admin/category/subject/Subjects.vue'),
    meta: {
      pageTitle: 'Quản lý môn học',
      breadcrumb: [
        {
          text: 'Quản lý đào tạo',
          active: false,
        },
        {
          text: 'Quản lý môn học',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.SUBJECT,
    },
  },
  {
    path: '/category/factTitles',
    name: 'factTitles',
    component: () => import('@/views/admin/category/fact-title/Index.vue'),
    meta: {
      pageTitle: 'Danh sách Chức danh',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Danh sách chức danh',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_TITLE,
    },
  },
  {
    path: '/admin/category/employees',
    name: 'employees',
    component: () => import('@/views/admin/category/employee/Employee.vue'),
    meta: {
      // pageTitle: 'Danh sách cán bộ',
      breadcrumb: [
        {
          text: 'Quản lý nhân sự',
          active: false,
        },
        {
          text: 'Danh sách cán bộ',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.EMPLOYEE,
    },
  },
  {
    path: '/admin/category/employees/import',
    name: 'importEmployee',
    component: () => import('@/views/admin/category/employee/importEmployee/Index.vue'),
    meta: {
      // pageTitle: 'Danh sách cán bộ',
      breadcrumb: [
        {
          text: 'Quản lý nhân sự',
          active: false,
        },
        {
          text: 'Import danh sách cán bộ',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.EMPLOYEE,
    },
  },
  {
    path: '/category/teacherTeams',
    name: 'teacherTeams',
    component: () => import('@/views/admin/category/teacherTeam/TeacherTeam.vue'),
    meta: {
      pageTitle: 'Danh sách tổ bộ môn',
      breadcrumb: [
        {
          text: 'Quản lý nhân sự',
          active: false,
        },
        {
          text: 'Tổ bộ môn',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.TEACHER_TEAM,
    },
  },
  {
    path: '/category/semesterType',
    name: 'categorySemesterType',
    component: () => import('@/views/admin/category/semester-type/SemesterType.vue'),
    meta: {
      pageTitle: 'Loại học kỳ',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Loại học kỳ',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.SEMESTERTYPE,
    },
  },
  {
    path: '/category/semester',
    name: 'categorySemester',
    component: () => import('@/views/admin/category/semester/index.vue'),
    meta: {
      pageTitle: 'Danh mục Học kỳ',
      breadcrumb: [
        {
          text: 'Hệ thống',
          active: false,
        },
        {
          text: 'Danh sách Học kỳ',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.SEMESTER,
    },
  },
  {
    path: '/category/trainingForm',
    name: 'categoryTrainingForm',
    component: () => import('@/views/admin/category/fact-training-form/Index.vue'),
    meta: {
      pageTitle: 'Loại hình đào tạo',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Loại hình đào tạo',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.TRAININGFORM,
    },
  },
  {
    path: '/category/trainingSystem',
    name: 'categoryTrainingSystem',
    component: () => import('@/views/admin/category/training-system/index.vue'),
    meta: {
      pageTitle: 'Hệ đào tạo',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Hệ đào tạo',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.TRAININGSYSTEM,
    },
  },
  {
    path: '/category/course',
    name: 'categoryCourse',
    component: () => import('@/views/admin/category/course/index.vue'),
    meta: {
      pageTitle: 'Quản lý Khóa học',
      breadcrumb: [
        {
          text: 'Quản lý đào tạo',
          active: false,
        },
        {
          text: 'Quản lý Khóa học',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.COURSE,
    },
  },
  {
    path: '/category/conduct',
    name: 'categoryConduct',
    component: () => import('@/views/admin/category/conduct/Conduct.vue'),
    meta: {
      pageTitle: 'Danh sách Bộ tiêu chí đánh giá',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Bộ tiêu chí đánh giá',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.CONDUCT,
    },
  },
  {
    path: '/category/factOrganizations',
    name: 'factOrganizations',
    component: () => import('@/views/admin/category/fact-organization/Index.vue'),
    meta: {
      pageTitle: 'Danh sách cơ quan/đơn vị',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Danh sách đơn vị',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_ORGANIZATION,
    },
  },
  {
    path: '/category/student-status',
    name: 'studentStatus',
    component: () => import('@/views/admin/category/fact-student-status/Index.vue'),
    meta: {
      pageTitle: 'Danh sách trạng thái sinh viên',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Danh sách trạng thái sinh viên',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_STUDENT_STATUS,
    },
  },
  {
    path: '/category/student-credit-class-status',
    name: 'studentCreditClassStatus',
    component: () => import('@/views/admin/category/fact-student-credit-class-status/Index.vue'),
    meta: {
      pageTitle: 'Danh sách trạng thái sinh viên lớp độc lập',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Danh sách trạng thái sinh viên lớp độc lập',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_STUDENT_CREDIT_CLASS_STATUS,
    },
  },
  {
    path: '/category/research-invention-type',
    name: 'researchInventionType',
    component: () => import('@/views/admin/category/fact-research-invention-type/Index.vue'),
    meta: {
      pageTitle: 'Danh sách loại giải pháp, sáng chế',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Danh sách loại giải pháp, sáng chế',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_RESEARCH_INVENTION_TYPE,
    },
  },
  {
    path: '/category/ward',
    name: 'ward',
    component: () => import('@/views/admin/category/fact-ward/Index.vue'),
    meta: {
      pageTitle: 'Danh sách phường/xã',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Danh sách phường/xã',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_WARD,
    },
  },
  {
    path: '/category/article-category',
    name: 'portalArticleCategory',
    component: () => import('@/views/admin/category/fact-article-category/Index.vue'),
    meta: {
      pageTitle: 'Danh sách Loại bài viết',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Danh sách Loại bài viết',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.PORTAL_ARTICLE_CATEGORY,
    },
  },
  {
    path: '/category/ethnics',
    name: 'factEthnics',
    component: () => import('@/views/admin/category/fact-ethnic/Index.vue'),
    meta: {
      pageTitle: 'Danh sách Dân tộc',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Danh sách Dân tộc',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_ETHNIC,
    },
  },
  {
    path: '/category/departmentType',
    name: 'departmentType',
    component: () => import('@/views/admin/category/department-type/Index.vue'),
    meta: {
      pageTitle: 'Danh sách loại phòng ban',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Danh sách loai phòng ban',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_DEPARTMENT_TYPE,
    },
  },
  {
    path: '/category/chart',
    name: 'chart',
    component: () => import('@/views/admin/category/charts/Chart.vue'),
    meta: {
      pageTitle: 'Biểu đồ tổng quan',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Biểu đồ tổng quan',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.CHARTS,
    },
  },
  {
    path: '/category/factAcademicRank',
    name: 'factAcademicRank',
    component: () => import('@/views/admin/category/fact-academic-rank/Index.vue'),
    meta: {
      pageTitle: 'Học hàm',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Học hàm',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_ACADEMIC_RANK,
    },
  },
  {
    path: '/category/factAcademicDegree',
    name: 'factAcademicDegree',
    component: () => import('@/views/admin/category/fact-academic-degree/Index.vue'),
    meta: {
      pageTitle: 'Học vị',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Học vị',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_ACADEMIC_DEGREE,
    },
  },
]
