import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    roles: [],
  },
  getters: {
    roles: state => state.roles,
  },
  mutations: {
    SET_ROLES: (state, data) => {
      state.roles = data
    },
  },
  actions: {
    async getAll({ commit }) {
      try {
        const response = await axios.get('uni/factResearchArticleRoles/all')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_ROLES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
